import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const TagCard = ({ tag }) => {
    const url = `/tag/${tag.slug}/`
    const featureImage = tag.feature_image ? tag.feature_image : '../images/tag.png'

    return (
        <Link to={url} className="post-card">
            <header className="post-card-header">
                {tag.feature_image &&
                    <div className="post-card-image" style={{
                        backgroundImage: `url(${featureImage})` ,
                    }}></div>}
                <h2 className="post-card-title">{`${tag.name} (${tag.postCount})`}</h2>
            </header>
        </Link>
    )
}

TagCard.propTypes = {
    tag: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        postCount: PropTypes.number
    }).isRequired,
}

export default TagCard
